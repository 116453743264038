\
<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-form-select
        v-if="
          userData.role_name == 'GLOBAL_ADMIN' ||
          userData.role_name == 'ACCOUNT_MANAGER'
        "
        class="regionSelector mr-1"
        id="region_type"
        v-model="selectedRegion"
        :options="regionTypes"
        value-field="id"
        text-field="name"
        v-on:change="changeRegion(selectedRegion)"
      />
      <!-- <locale /> -->
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BFormSelect, BLink, BNavbarNav } from "bootstrap-vue";
import Locale from "./components/Locale.vue";
import DarkToggler from "./components/DarkToggler.vue";
import SearchBar from "./components/SearchBar.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import axios from "@axios";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BLink,
    BFormSelect,

    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    SearchBar,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    changeRegion(region) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const regionCurrencyData = JSON.parse(
        localStorage.getItem("regionCurrencyData")
      );
      const token = localStorage.getItem("accessToken");

      for (const [key, value] of Object.entries(
        regionCurrencyData.regions_details
      )) {
        if (value.id == region) {
          userData.deafult_currency_code = value.currencies[0].code;
          store.commit("app/CURRENCY_TYPE", value.currencies[0].code);
        }
      }

      userData.default_region_id = region;
      if (userData.role_name == "GLOBAL_ADMIN") {
        axios
          .get(`/organizations/region/${userData.default_region_id}`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            const orgs = [];
            for (const [key, value] of Object.entries(response.data.items)) {
              orgs.push(value.id);
            }
            userData.orgs_list = orgs;
            localStorage.setItem("userData", JSON.stringify(userData));
            store.commit("app/USER_DATA", userData);
          })
          .catch((err) => {});
      } else if (userData.role_name == "ACCOUNT_MANAGER") {
        axios
          .get(`/organizations/account_manager/${userData.id}`, {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              region_id: region,
            },
          })
          .then((response) => {
            const orgs = [];
            for (const [key, value] of Object.entries(response.data)) {
              orgs.push(value.id);
            }
            userData.orgs_list = orgs;
            localStorage.setItem("userData", JSON.stringify(userData));
            store.commit("app/USER_DATA", userData);
          })
          .catch((err) => {});
      } else {
        localStorage.setItem("userData", JSON.stringify(userData));
        store.commit("app/USER_DATA", userData);
      }
    },
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const regionCurrencyData = JSON.parse(
      localStorage.getItem("regionCurrencyData")
    );
    let regionTypes = ref([]);

    const selectedRegion = ref(userData.default_region_id);

    regionTypes.value = regionCurrencyData.regions_details;

    return {
      userData,
      regionTypes,
      selectedRegion,
    };
  },
};
</script>
<style>
.regionSelector {
  width: 100px !important;
  height: 30px !important;
}
</style>
