<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";
import store from "@/store";
import { ref } from "@vue/composition-api";
export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {};
  },

  // const store.state.app.userData = store.state.app.store.state.app.userData;
  setup() {
    const navMenuItems = ref([]);
    navMenuItems.value.push({
      header: "Home",
      title: "Home",
      icon: "HomeIcon",
      route: "dashboard-usa",
      visible: true,
    });

    if (
      store.state.app.userData.role_name == "GLOBAL_ADMIN" ||
      store.state.app.userData.role_name == "ACCOUNT_MANAGER"
    ) {
      navMenuItems.value.push({
        header: "Admin",
        title: "Admin",
        icon: "UserIcon",
        tag: "2",
        children: [
          {
            title: "Organizations",
            route: "apps-organizations-list",
          },
          {
            title: "Users",
            route: "apps-users-list",
          },
        ],
      });
    } else if (
      store.state.app.userData.role_name == "ORG_ADMIN" &&
      store.state.app.userData.role_name !== "EMPLOYEE"
    )
      navMenuItems.value.push({
        header: "Admin",
        title: "Admin",
        icon: "UserIcon",
        children: [
          {
            title: "Users",
            route: "apps-users-list",
          },
        ],
      });

    navMenuItems.value.push(
      {
        header: "Support",
        title: "Support",
        icon: "HeadphonesIcon",
        children: [
          // {
          //     title: "Overview",
          //     route: "apps-overview",
          //   },
          {
            title: "Tickets",
            route: "apps-support-cases-list",
          },
          {
            title: "AI Chatbot",
            route: "apps-chatgpt",
          },
        ],
      },
      {
        header: "Monitoring",
        title: "Monitoring",
        icon: "MonitorIcon",
        children: [
          {
            title: "GCP Services Status",
            route: "apps-gcp-helpers-list",
          },
        ],
      },
      {
        header: "Security",
        title: "Security",
        icon: "ShieldIcon",
        children: [
          {
            title: "Scans",
            route: "app-scoutsuite",
          },
        ],
      }
    );
    if (
      store.state.app.userData.role_name == "ACCOUNT_MANAGER" ||
      store.state.app.userData.role_name == "GLOBAL_ADMIN"
    ) {
      navMenuItems.value.push({
        header: "Reports",
        title: "Reports",
        icon: "ClipboardIcon",
        children: [
          {
            title: "Discounts",
            route: "apps-discounts-report-list",
          },
          {
            title: "Partner FinOps Overview",
            route: "dashboard-partner",
          },
          // {
          //   title: "Anomalies",
          //   route: "apps-anomalies-report-list",
          // },
        ],
      });
    } else {
      navMenuItems.value.push({
        header: "Reports",
        title: "Reports",
        icon: "ClipboardIcon",
        children: [
          {
            title: "Discounts",
            route: "apps-discounts-report-list",
          },
          // {
          //   title: "Anomalies",
          //   route: "apps-anomalies-report-list",
          // },
        ],
      });
    }

    return {
      navMenuItems,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
