<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <b-img
              :src="skinChange == 'dark' ? appLogoImageDark : appLogoImage"
              alt="logo"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import store from "@/store";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BLink,
    BImg,
  },
  watch: {
    "$store.state.appConfig.layout.skin": {
      handler() {
        this.skinChange = store.state.appConfig.layout.skin;
      },
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app;
    const skinChange = ref(store.state.appConfig.layout.skin);
    return {
      appName,
      appLogoImage,
      appLogoImageDark,
      skinChange,
    };
  },
};
</script>
